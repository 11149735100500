// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJz5Y5K8BVFH3HXNskWPsNT2uhDirhJbc",
  authDomain: "react-app-62325.firebaseapp.com",
  databaseURL: "https://react-app-62325-default-rtdb.firebaseio.com",
  projectId: "react-app-62325",
  storageBucket: "react-app-62325.appspot.com",
  messagingSenderId: "1016360046633",
  appId: "1:1016360046633:web:4b048809747085bff64c38",
  measurementId: "G-0QZ7YWY387"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export {db, auth, analytics, app};

